$(document).on('turbolinks:load', function() {
  //////flashメッセージのフェードアウト
  $(function(){
    setTimeout("$('.flash').fadeOut('slow')", 3000);
  });
});

// $(document).on('turbolinks:load', function() {
//   // ロット5kg以上でsubmit有効化
//   $('.order-number').on('change', function() {
//     var weight =
//   })
// });
