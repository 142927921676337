$(document).on('turbolinks:load', function() {
  $('#select-btn').on('click', function() {
    var supplier = $('#selected-supplier').val();

    if (supplier != null && supplier.length !== 0) {
      $.ajax({
        type: 'GET',
        url: '/admin/orders/products_search',
        data: { supplier: supplier },
        dataType: 'json'
      })
      .done(function(list) {
        $('#product-search-result').empty();
        if (list != null && list.length !== 0) {
          $('#product-search-result').html(list.html);
        } else {
          $('#product-search-result').empty();
          alert('表示するリストはありません');
        }
      })
      .fail(function () {
        alert('商品一覧を取得できませんでした');
      })
    } else {
      $('#product-search-result').empty();
    }
  });

  $('.product-id').on('change', function() {
    var parent = $(this).parents('.table-row');
    var product_id = $(parent).find('.product-id').val();
    var product_name_field = $(parent).find('.product-name');
    var product_unit_field = $(parent).find('.product-unit');

    if (product_id != null && product_id.length !== 0) {
      $.ajax({
        type: 'GET',
        url: '/admin/orders/product_search',
        data: { product_id: product_id },
        dataType: 'json'
      })
        .done(function (product) {
          $(product_name_field).text(product.name);
          $(product_unit_field).text(product.unit);
        })
        .fail(function () {
          alert('商品詳細を取得できませんでした');
        })
    } else {
      $(product_name_field).text();
      $(product_unit_field).text();
    }
  });

  $('#selected-user').on('change', function() {
    var user_id = $(this).val();
    if (user_id) {
      $.ajax({
        type: 'GET',
        url: '/admin/orders/supplier_search',
        data: { user_id: user_id },
        dataType: 'json'
      })
      .done(function (response) {
        var suppliers = response.suppliers;
        console.log(suppliers)
        $('#selected-supplier').empty();
        $.each(suppliers, function(index, supplier) {
          $('#selected-supplier').append($('<option>').val(supplier.id).text(supplier.name));
        });
      })
      .fail(function() {
        alert('取引先を取得できませんでした');
      })
    } else {
      $('#selected-supplier').empty();
      $('#selected-supplier').append($('<option>').val('').text(''));
    }
  });
})
