// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// import 'jquery/dist/jquery.js'
// import 'popper.js/dist/popper.js'
// import 'bootstrap/dist/js/bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'
// require("jquery")
import 'bootstrap';
import '../stylesheets/application.scss';
import './admin_order';
import './common';

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import '@fortawesome/fontawesome-free/js/all';
// import "@fortawesome/fontawesome-free/css/all";
